import React, { useState } from 'react';
import cx from 'classnames';
import dynamic from 'next/dynamic';

import styles from './index.module.scss';

const PreviewVideoSuccessStories = dynamic(() =>
  import('#app/UI/organisms/preview-video-success-stories')
);
const Button = dynamic(() => import('#app/UI/molecules/new-button'));
const Typography = dynamic(() => import('#app/UI/atoms/typography-updated'));

const dataSuccessStories = [
  {
    id: 1,
    title: 'Unicef México',
    description: 'Campamentos de capacitación digital y orientación educativa',
    urlVideo: 'https://www.youtube.com/watch?v=RqVkmZhsSUY',
    thumbnail: './home/success-stories/video_thumbnail_1.webp'
  },
  {
    id: 2,
    title: 'Samsung Innovation Campus 2022',
    description:
      'Programa de tecnología y desarrollo personal y social en 5 colegios de Lima',
    urlVideo: 'https://www.youtube.com/watch?v=yY6ShdC3vhQ',
    thumbnail: './home/success-stories/video_thumbnail_2.webp'
  },
  {
    id: 3,
    title: 'Clases de tecnología en colegios públicos de Barranquilla',
    description:
      'Estamos empoderando a más de 3.000 jóvenes con habilidades tecnológicas.',
    urlVideo: 'https://youtu.be/tybiGvoPbJ0',
    thumbnail: './home/success-stories/video_thumbnail_3.webp'
  }
];

const SuccessStories = () => {
  const [selectedVideo, setSelectedVideo] = useState(dataSuccessStories[0]);

  return (
    <section className={styles.container}>
      <div className={styles.header}>
        <Typography className={cx(styles.title)} tag="h2">
          Impacto en acción: <br className={styles.mobile} />
          <span className={styles.secondaryColor}> casos de éxito</span>
        </Typography>
      </div>

      <div className={styles.body}>
        <div className={styles.containerCards}>
          {dataSuccessStories.map((item, idx) => {
            const border = selectedVideo.id === item.id ? '4px solid #FFAA7D' : 'none';
            return (
              <div
                key={`video-success-storie-${idx}`}
                className={styles.thumbnail}
                style={{ border }}
                onClick={() => setSelectedVideo(item)}
              >
                <img key={idx} src={item.thumbnail} alt="" />
              </div>
            );
          })}
        </div>
        <div className={styles.player}>
          <PreviewVideoSuccessStories {...selectedVideo} />
        </div>
        <img
          className={styles.icon}
          src="/home/enhance-skills/card-type-business/icon-arrow.png"
          alt="icon"
        />
      </div>
      <Button
        className={styles.button}
        variant="orange"
        type="url"
        target="_blank"
        href="https://www.organizaciones.crackthecode.la/proyectos"
        textOptions={{ fontSize: [20, 20], color: '#001F19' }}
      >
        Explora más proyectos
      </Button>
    </section>
  );
};

export default SuccessStories;
